import Layout from 'common/layouts/Layout';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { LegalWrapper } from 'components/pages/legal/shared';
import { graphql } from 'gatsby';
import { LegalPageTemplateQuery } from 'graphqlTypes';
import React, { FC } from 'react';

interface LegalPageProps {
  data: LegalPageTemplateQuery;
}

const LegalPage: FC<LegalPageProps> = ({ data }) => (
  <Layout>
    <Navigation />
    <PageWrapper>
      <LegalWrapper>
        {data.markdownRemark && data.markdownRemark.html && (
          <div
            className="legal-agreement"
            dangerouslySetInnerHTML={{
              __html: data.markdownRemark.html,
            }}
          ></div>
        )}
      </LegalWrapper>
    </PageWrapper>
  </Layout>
);

export default LegalPage;

export const query = graphql`
  query LegalPageTemplate($pathSlug: String!) {
    markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
      html
    }
  }
`;
